import React from "react"
import styled from 'styled-components'
import Carousel from 'react-grid-carousel'
import Img from 'gatsby-image'

const Container = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: calc(${({ theme }) => theme.paddingBox}*2) auto;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        display: inherit;
    }
`

const Title = styled.h3`
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 1.9rem;
    font-weight: bold;
    margin-left: calc(${({ theme }) => theme.paddingBox});
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        flex-direction: row;
        width: ${({ theme }) => theme.maxWidth};
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: auto;
        text-align: left;
    }
`

const ContainerImg = styled.div`
    position: relative;
    width: 150px;
    margin:auto;
`
const ContainerCarousel = styled.div`
    margin-top: calc(${({ theme }) => theme.paddingBox}/2);
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        width: 70%;
        margin:auto;
        margin-top: ${({ theme }) => theme.paddingBox};
    }
`
const Image = styled(Img)`
`

const CarouselImage = ({title, items, cols, rows, gap, autoPlay }) => {
    return(
        <Container>
            <Title>{title}</Title>
        <ContainerCarousel>
            <Carousel cols={cols} rows={rows} gap={gap} autoplay={autoPlay} loop hideArrow 
                responsiveLayout={[
                {
                    breakpoint: 800,
                    cols: 2,
                    rows: 1,
                    gap: 10,
                    loop: true,
                    autoplay: 3000
                },
                {
                    breakpoint: 1280,
                    cols: 3,
                    rows: 1,
                    gap: 10,
                    loop: true,
                    autoplay: 3000
                }]}
            >
                {items.map(item => 
                    <Carousel.Item key={item.key}>
                        <ContainerImg >
                            <Image fixed={item.picture.asset.fixed}></Image>
                        </ContainerImg>
                    </Carousel.Item>
                )}
            </Carousel> 
        </ContainerCarousel>
        </Container>
    
    )
}

export default CarouselImage