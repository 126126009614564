import React from "react"
import { graphql } from "gatsby"
import { Structure } from "@lib/simba/_statics"
import { EffectBox } from "@lib/simba/divbox"
import Triptyque from "@lib/simba/triptyque"
import Seo from "@components/Seo"
import getSeo from "@utils/get-seo"
import { WEBSITE } from "@utils/get-schemas"
import getAction from "@utils/get-form-action"
import getSrc from "@utils/get-root"
import createLink from "@utils/create-link"
import BandeauHomeFirst from "@lib/simba/bandeau-home-first"
import ContentHome from "@lib/simba/content-home"
import BandeauMulti from "@lib/simba/bandeau-multi"
import Citations from "@lib/simba/citation"
import HomeArticle from "@lib/simba/home-article"
import HomeDual from "@lib/simba/home-dual"
import Contact from "@lib/simba/contact"
import CarouselImage from "@lib/simba/carousel-image"
import CarouselCard from "@lib/simba/carousel-card"
import { CarouselControl } from "../components/library/simba/CarouselControl"
import { Map } from "@lib/simba/contact"

export default ({ data }) => {
  console.log(data.site.siteMetadata.googleApiKey)
  return (
    <Structure isHome={true}>
      <Seo />
      <BandeauHomeFirst {...data.home} />
      <EffectBox effect="zoom-out" duration="800">
        <HomeArticle article={data.contentHome} />
      </EffectBox>
      <Triptyque
        items={data.triptyque.nodes}
        title="Notre methode"
      />
      <HomeDual pictures={data.dual}/>
      <Contact contact={data.contactcomponent} coord={data.coord} googleKey={data.site}/>
    </Structure>
  )
}

export const query = graphql`
  query MainQuery {
    site {
      siteMetadata {
        clientToken
        siteUrl
        title
        googleApiKey
      }
    }
    contentHome: sanityContenu(slug: { current: { eq: "notre-cabinet" } }) {
      slug {
        current
      }
      title
      subtitle
      resume
      _rawContent
      links {
        ... on SanityContenu {
          __typename
          id
          slug {
            current
          }
          title
        }
        ... on SanityCategory {
          __typename
          id
          slug {
            current
          }
          title
        }
      }
      cover {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    home: sanityContenu(slug: { current: { eq: "bandeau-home-first" } }) {
      slug {
        current
      }
      title
      resume
      subtitle
      cover {
        asset {
          fluid (maxWidth: 1280){
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    coord: sanityIdentity {
      name
      phone
      mobile
      email
      address
      city
      postalcode
    }
    contactcomponent: sanityContenu(slug: { current: { eq: "composant-contact" } }) {
      resume
      subtitle
      cover {
        asset {
          fluid (maxWidth: 2480){
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    dual: allSanityContenu(limit: 2, sort: {fields: order}, filter: {categories: {elemMatch: {slug: {current: {eq: "dual"}}}}}) {
      nodes {
        id
        title
        links {
          ... on SanityContenu {
            __typename
            id
            slug {
              current
            }
            title
          }
          ... on SanityCategory {
            __typename
            id
            slug {
              current
            }
            title
          }
        }
        cover {
          asset {
            fluid{
              ...GatsbySanityImageFluid
            }
          }
        }
        icon {
          asset {
            fluid{
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    triptyque: allSanityContenu(
      limit: 5
      sort: { fields: order }
      filter: {
        categories: { elemMatch: { slug: { current: { eq: "triptyque" } } } }
      }
    ) {
      nodes {
        id
        title
        subtitle
        resume
        _rawContent
        slug {
          current
        }
        icon {
          asset {
            fluid(maxWidth: 100) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    clients: allSanityCarousels(
      filter: { slug: { current: { eq: "nos-clients" } } }
    ) {
      nodes {
        title
        items {
          title
          _key
          picture {
            asset {
              fixed(width: 135) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
    }
    services: allSanityCarousels(
      filter: { slug: { current: { eq: "nos-services" } } }
    ) {
      nodes {
        title
        items {
          title
          _key
          content
          identifiant
          links {
            ... on SanityContenu {
              id
              slug {
                current
              }
            }
          }
          picture {
            asset {
              fluid(maxWidth: 1280, maxHeight: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
