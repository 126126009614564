import React from 'react';
import styled from 'styled-components'
import Carousel from 'react-grid-carousel'
import Img from 'gatsby-image'
import { Link } from "gatsby"

const Container = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    margin-bottom: calc(${({ theme }) => theme.paddingBox}*2);
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        display: inherit;
        width: 60%;
        margin-top: calc(${({ theme }) => theme.paddingBox}*2);
        display: none;
    }
`
const ContainerCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin: calc(${({ theme }) => theme.paddingBox}/2);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 1rem;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        flex-direction: row;
    }
`
const ContainerImg = styled.div`
    width: 100%;
    min-width: 100%;
    max-height: 10%;
    height: 10%;
    border-radius: 1rem;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        width: 50%;
        min-width: 50%;
        max-width: 50%;
        min-height: 50%;
        height: auto;
    }
`
const ContainerCarousel = styled.div`
    margin-top: calc(${({ theme }) => theme.paddingBox}/2);
    display: flex;
    flex-direction: column;
    
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        margin-top: ${({ theme }) => theme.paddingBox};
    }
`
const Image = styled(Img)`
    border-radius: 1rem 0 0 1rem;
`
const TravauxList = styled.div`
    display: grid
    grid-template-columns: 1fr 1fr;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.secondary};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        display: flex;
        justify-content: space-between;
    }
`
const Travaux = styled.h3`
    color: ${({ theme }) => theme.colors.secondary};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 2rem;
    padding: 0.5rem;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-bottom: calc(${({ theme }) => theme.paddingBox}/2);
    width: 55%;
    font-weight: normal;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        font-size: 1.5rem;
        padding: 0.9rem;
        width: inherit;
    }
    &:hover{
        color: white;
        background: ${({ theme }) => theme.colors.secondary};
        transition-duration: 0.5s;
        transition-property: background-color color;
        transition-timing-function: ease-in-out;
    }
`
const CardText = styled.div`
    color: black;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: auto;
    padding: calc(${({ theme }) => theme.paddingBox}/2);
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        padding: calc(${({ theme }) => theme.paddingBox}*2);
        width: inherit;
        height: inherit;
    }
`
const CardTitle = styled.div`
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: ${({ theme }) => theme.paddingBox};
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        font-size: 2rem;
        text-align: inherit;
    }
`
const CardContent = styled.div`
    font-size: 1rem;
    margin-bottom: ${({ theme }) => theme.paddingBox};
`
const Button = styled(Link)`
    color: ${({ theme }) => theme.colors.secondary};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 2rem;
    padding: 0.5rem;
    font-size: 1.1rem;
    margin:auto;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        font-size: 1.5rem;
        padding: 0.5rem;
        width: auto;
        max-width: 70%;
        text-align: center;
        margin: ${({ theme }) => theme.paddingBox} 0;
    }
    
`
const CarouselCard = ({ items, cols, rows, gap }) => {
    return(
        <Container>
        {/* <TravauxList>
                {items.map(item => <Link to={`/${item.links[0].slug.current}`}><Travaux>{item.title}</Travaux></Link>)}
        </TravauxList> */}
        <ContainerCarousel>
            <Carousel cols={cols} rows={rows} gap={gap} loop hideArrow autoplay={5000} >
                {items.map(item => 
                    <Carousel.Item key={item.key}>
                        <ContainerCard>
                            <ContainerImg>
                                <Image fluid={item.picture.asset.fluid}></Image>
                            </ContainerImg>
                            <CardText>
                                <CardTitle>{item.title}</CardTitle>
                                <CardContent>{item.content}</CardContent>
                                <Button to={`/${item.links[0].slug.current}`}>En savoir plus</Button>
                            </CardText>
                        </ContainerCard>
                    </Carousel.Item>
                )}
            </Carousel> 
        </ContainerCarousel>
        </Container>
    
    )
}

export default CarouselCard