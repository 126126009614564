import React from 'react'
import styled from 'styled-components'
import Element from './Element'

const Case = styled.div``

const Container = styled.div`
    padding: ${({ theme }) => `${theme.paddingBox}`};
    ${Case} {
        margin: ${({ theme }) => `${theme.paddingBox} 0`};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        ${Case} {
            margin: 0;
        }
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(4, 200px);
        grid-auto-rows: calc(200px / 4);
        column-gap: 4px;
        row-gap: 4px;
        ${Case}:nth-child(1) {
            grid-column: 1;
            grid-row: 3 / 7;
        }
        ${Case}:nth-child(2) {
            grid-column: 2;
            grid-row: 1 / 5;
        }
        ${Case}:nth-child(3) {
            grid-column: 2;
            grid-row: 5 / 9;
        }
        ${Case}:nth-child(4) {
            grid-column: 3;
            grid-row: 2 / 6;
        }
        ${Case}:nth-child(5) {
            grid-column: 3;
            grid-row: 6 / 10;
        }
        ${Case}:nth-child(6) {
            grid-column: 4;
            grid-row: 4 / 8;
        }
    }
`

const BandeauMulti = ({ els }) => (
    <Container>
        {els.map((el) => (
            <Case key={el.id}><Element {...el} /></Case>
        ))}
    </Container>
)

export default BandeauMulti