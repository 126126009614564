import React from "react"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import Input from './Champs'
import { FaPhoneAlt } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { IoMdPin } from 'react-icons/io';

const ContactContainer = styled.div`
    width: 100vw;
    color: white;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    }
`
const StyledBackgroundImage = styled(BackgroundImage)`
    &::after, &::before {
        filter: brightness(0.6);
    }
    color: white;

`
const Containers = styled.div`
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        flex-direction: row;
    }
`
const FormContainer = styled.div`
    padding: ${({ theme }) => theme.paddingBox};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        justify-content: center;
        min-height: 80vh;
    }
`

const InfoContainer = styled.div`
    padding: ${({ theme }) => theme.paddingBox};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) 
    }
`
const TextContainer = styled.div`
    position: relative;
    width: 100%;
    top: -25px;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        width: 50%;
        left: -100px;
    }
`
const Title = styled.div`
    font-size: 1.5rem;
    margin-bottom: calc(${({ theme }) => theme.paddingBox}/2);
    margin-top: ${({ theme }) => theme.paddingBox};
    text-transform: uppercase;
    font-weight: lighter;
`
const Resume = styled.div`
    font-size: 2.3rem;
    width: 100%;
    line-height: 3rem;
    &:after{
        position: absolute;
        content: '';
        height: 2px;
        bottom: -5px; 
        left: 0;
        width: 100px;
        background: white;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        font-size: 3rem;
        line-height: 3.5rem;
    }
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        width: 50%;
    }
`
const Submit = styled.input`
    margin: auto;
    width: 50%;
    max-width: 150px;
    cursor: pointer;
    color: ${({ theme }) => theme.colorTextTitle};
    background-color: ${({ theme }) => theme.colors.secondary};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 1rem;
    padding: 7px 1rem;
    font-size: 0.9rem;
    text-transform: uppercase;
    display: block;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        align-self: flex-end;
        font-size: 1.5rem;
        max-width: 100%;
        padding: 7px 2rem;
        text-align: center;
    }
`
const ChampsTextArea = styled.div`
    display: flex;
    position: relative;
`
const Textarea = styled.textarea`
    background: white;
    position: relative;
    right: -75px; top: 0;
    width: 70%;
    height: 100px;
    border: 0;
    background: white;
    padding: ${({ theme }) => `calc(${theme.paddingBox} / 2)`};
    margin: ${({ theme }) => `calc(${theme.paddingBox} / 2) 0`};
    border-bottom: 1px solid ${({ theme }) => theme.colorBorder};
    border-radius: 1rem;
    outline: none;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        width: 100%;
        right: 0px;
    }
`
const Label = styled.label`
    position: absolute;
    font-size: 1rem;
    top: 15px; 
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        font-size: 1.2rem;
        left: -100px;
    }
`
const Identity = styled.div`
    max-width:90vw;
    font-size: 1.1rem;
    padding: calc(${({ theme }) => theme.paddingBox}/2);
    margin-bottom: ${({ theme }) => theme.paddingBox};
    margin-right: ${({ theme }) => theme.paddingBox};
    & a{
        color: white;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: 1.5rem;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        padding: ${({ theme }) => theme.paddingBox};
    }
  
`
const Icon = styled.span`
    position: relative;
    left:-10px; top:0px;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        margin-right: ${({ theme }) => theme.paddingBox};
    }
`
const Adresse = styled.span`
    position: relative;
    left:15px; top:-5px;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        left:45px; top:-10px;
    }
`
const Map = styled.iframe`
    width: 90%;
    height: 300px;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        width: 60%;
        height: 400px;
    }
`

const Contact = ({contact, coord, googleKey}) => {
    const apiKey = googleKey.siteMetadata.googleApiKey
    return(
        <ContactContainer>
            <StyledBackgroundImage
                fluid={contact.cover.asset.fluid}
            >  
                <Containers>
                    <FormContainer>
                        <TextContainer>
                            <Title>{contact.subtitle}</Title>
                            <Resume>{contact.resume}</Resume>
                        </TextContainer>
                        <Form
                            action="https://io.jackandrussells.com/api/sender/send"
                            accept-charset="UTF-8"
                            enctype="multipart/form-data"
                            method="POST"
                        >
                            <input type="hidden" name="_jessica" />
                            <input
                                type="hidden"
                                name="_token"
                                value={process.env.GATSBY_CLIENT_TOKEN}
                            />
                            <Input name="nom" label="Nom" placeholder="" />
                            <Input name="tel" label="Téléphone" placeholder="" />
                            <Input name="email" label="Email" placeholder="" />
                            <ChampsTextArea>
                                <Label for="message">Message</Label>
                                <Textarea name="message" placeholder=""></Textarea>
                            </ChampsTextArea>
                            <Submit type="submit" value="Envoyer" />
                        </Form>
                    </FormContainer>
                    <InfoContainer>
                        <Identity>
                            <div><a href={`tel:${coord.phone}`}><Icon><FaPhoneAlt/></Icon>{coord.phone}</a></div>
                            <div><a href={`mailto:${coord.email}`}><Icon><HiOutlineMail/></Icon>{coord.email}</a></div>
                            <div><Icon><IoMdPin/></Icon>{coord.address} <br/><Adresse>{coord.postalcode} {coord.city}</Adresse></div>
                        </Identity>
                        <Map
                            title={`Google map : ${coord.address}`}
                            loading="lazy"
                            allowFullScreen
                            src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${coord.address}`}
                        ></Map>
                    </InfoContainer>
                </Containers>
            </StyledBackgroundImage>
        </ContactContainer>
    )
}

export default Contact


