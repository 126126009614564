import React from "react"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"

const Container = styled.section`
  padding: ${({ theme }) => theme.paddingBox};
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    width: ${({ theme }) => theme.breakpoints.desktop};
    margin: 0 auto;
  }
`
const Title = styled.h1`
  color: #91c11d;
  text-transform: uppercase;
  font-size: 5vw;
  letter-spacing: 2px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    font-size: 1.8vw;
  }
`
const Content = styled.div`
  line-height: 1.25;
  font-size: 7vw;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    font-size: 2.5vw;
  }
`

const Component = ({ title, text }) => (
  <Container>
    <Title>{title}</Title>
    <Content>{text}</Content>
  </Container>
)

export default Component
