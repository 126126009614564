import React from "react"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import { FaPhoneAlt } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { IoMdPin } from 'react-icons/io';

const Container = styled.div`
    color: white;
`
const StyledBackgroundImage = styled(BackgroundImage)`
    &::after, &::before {
        filter: brightness(0.6);
    }
    color: white;
`
const InfoContainer = styled.div`
    padding: ${({ theme }) => theme.paddingBox};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) 
    }
`

const Identity = styled.div`
    max-width:90vw;
    font-size: 1.2rem;
    padding: calc(${({ theme }) => theme.paddingBox}/2);
    margin-bottom: ${({ theme }) => theme.paddingBox};
    margin-right: ${({ theme }) => theme.paddingBox};
    & a{
        color: white;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        font-size: 1.5rem;
        padding: ${({ theme }) => theme.paddingBox};
    }
  
`
const Icon = styled.span`
    position: relative;
    left:-10px; top:0px;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        margin-right: ${({ theme }) => theme.paddingBox};
    }
`
const Adresse = styled.span`
    position: relative;
    left:17px; top:-10px;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {

        left:45px; top:-15px;
    }
`

const Map = ({contact, coord, googleKey}) => {
    const apiKey = googleKey.siteMetadata.googleApiKey
    return(
        <Container>
            <StyledBackgroundImage
                fluid={contact.cover.asset.fluid}
            >
                <InfoContainer>
                    <Identity>
                        <div><a href={`tel:${coord.phone}`}><Icon><FaPhoneAlt/></Icon>{coord.phone}</a></div>
                        <div><a href={`mailto:${coord.email}`}><Icon><HiOutlineMail/></Icon>{coord.email}</a></div>
                        <div><Icon><IoMdPin/></Icon>{coord.address} <br/><Adresse>{coord.postalcode} {coord.city}</Adresse></div>
                    </Identity>
                    <iframe
                        width="100%"
                        height="300"
                        loading="lazy"
                        allowFullScreen
                        src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${coord.address}`}
                    ></iframe>
                </InfoContainer>
            </StyledBackgroundImage>
        </Container>
    )
}

export default Map


