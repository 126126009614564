import React from "react"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"
import { Link } from "gatsby"

const Container = styled.section`
  width: 100vw;
  overflow: hidden;
  background: #f1f1f2;
`
const DoublePicturesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
  }
`
const ContainerImg = styled.div`
  position: relative;
  margin: calc(${({ theme }) => theme.paddingBox}*2) 0;
  background-size: cover;
  &:first-child{
    margin-left: 0;
  }
  &:last-child{
    margin-top:0;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: calc(${({ theme }) => theme.paddingBox}*3) 0;
    margin-left: calc(${({ theme }) => theme.paddingBox}*3); 
    &:last-child{
      margin-top:inherit;
    }
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {   
  }
`
const IconContainer = styled.div`
  z-index: 9999;
  position: absolute;
  top: 50%; right: calc(${({ theme }) => theme.paddingBox}/2);
  margin: 0;
  height: 50px;
  width: 50px;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    height: 75px;
    width: 75px;
    right: ${({ theme }) => theme.paddingBox};
  }
`
const StyledBackgroundImage = styled(BackgroundImage)`
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
  min-height: 500px;
  width: 90vw;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50vw;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    min-height: 700px;
    width: 33vw;
  }
  &::after, &::before {
    filter: brightness(0.8);
  }
`
const TitleContainer = styled.div`
  position: absolute;
  bottom: 5%; 
  left: 15%;
  width: 100%;
  height: 15%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:9999;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    left: 15%;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    left: 15%;
  }
`
const TitlePicture = styled.p`
  position: absolute;
  bottom:-15px; left:0;
  color: white;
  font-size: 2rem;
  padding: calc(${({ theme }) => theme.paddingBox}/2);
  &:after{
    position: absolute;
    content: '';
    height: 2px;
    bottom: calc(${({ theme }) => theme.paddingBox}/2); 
    left: calc(${({ theme }) => theme.paddingBox}/2);
    width: 75px;
    background: ${({ theme }) => theme.colors.secondary};
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 3rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 0;
    &:after{
      bottom: calc(${({ theme }) => theme.paddingBox}/5);
      left: calc(${({ theme }) => theme.paddingBox}/5);
    }
  }
`


const HomeDual = (pictures) =>(
  <Container>
    <DoublePicturesContainer>
    {pictures.pictures.nodes.map(picture =>  
        <ContainerImg >
          <Link to={`/${picture.links[0].slug.current}`}>
            <IconContainer>
              <Img 
                fluid={picture.icon.asset.fluid} 
              />
            </IconContainer>
            <StyledBackgroundImage 
              fluid={picture.cover.asset.fluid} 
              imgStyle={{
                objectFit: "cover",
                objectPosition: "center center",
              }}
            />
            <TitleContainer>
              <TitlePicture>{picture.title}</TitlePicture>
            </TitleContainer>
          </Link>
        </ContainerImg>
    )}
    </DoublePicturesContainer>
    
  </Container>
)


export default HomeDual
