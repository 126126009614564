import React from "react"
import styled from "styled-components"
import Img from 'gatsby-image'

const Container = styled.section`
  max-height: 55vh;
  overflow: hidden;
  position: relative;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-height:75vh;
  }
`
const ContainerImg = styled.div`
  position: relative;
  width: 100vw;
  max-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:auto;
`
const StyledImage = styled(Img)`
  width: 100%;
  max-height: 50vh;
  padding: ${({ theme }) => theme.paddingBox};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 0;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-height:70vh;
  }
`

const Infos = styled.div`
  position: absolute;
  bottom: 0; right: 0;
  z-index:5000;
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  padding: calc(${({ theme }) => theme.paddingBox}/2);
  background: linear-gradient(to top,transparent, rgba(0,0,0, 0.6) 30% 70%,transparent);
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: calc(${({ theme }) => theme.paddingBox}*3);
  }
`
const Title = styled.h1`
  font-size: 1.3rem;
  font-weight: normal;
  color: white;
  margin: 0;
  z-index:9999;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (min-width: ${({ theme }) =>theme.breakpoints.desktop}) {
    font-size: 3.5rem;
    padding: 0 ${({ theme }) => theme.paddingBox};
    width: 100%;
  }
`
const Resume = styled.div`
  color: white;
  font-size: 0.9rem;
  margin: 0;
  width: 60%;
  display: flex;
  justify-content: flex-end;
  padding: calc(${({ theme }) => theme.paddingBox}/2) 0 ${({ theme }) => theme.paddingBox} 0;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 2rem;
    padding: calc(${({ theme }) => theme.paddingBox}/2) ${({ theme }) => theme.paddingBox};
    line-height:1;
    width: 100%;
  }
`


const Bandeau = ({ title, subtitle, cover }) => (
  <Container>
    <ContainerImg >
      <StyledImage Tag="section" fluid={cover.asset.fluid} />
        <Infos>
          <Title>{title}</Title>
          <Resume>{subtitle}</Resume>
        </Infos>
    </ContainerImg>
  </Container>
)

export default Bandeau
