import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

const ImgContainer = styled.div`
  grid-area: 1/1;
  position: relative;
  transition: all 0.2s ease-in;
`

const TextContainer = styled.div`
  grid-area: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 10vw;
  text-align: center;
  padding: calc(${({ theme }) => theme.paddingBox} / 2);
  line-height: 1.1;
  overflow: hidden;
  transition: all 0.4s ease-out;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    font-size: 1.33vw;
  }
  &:after {
    transition: all 0.2s ease-out;
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    background: white;
    bottom: ${({ theme }) => theme.paddingBox};
  }
  &:hover {
    background: rgba(0, 0, 0, 0.6);
    &:after {
      width: ${({ theme }) => `calc(100% - ${theme.paddingBox})`};
    }
  }
`

const StyledLink = styled(Link)`
  display: grid;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  &:hover {
    ${ImgContainer} {
      transform: scale(1.1);
    }
  }
`

const getLink = (type, slug) =>
  type === "SanityContenu" ? `/${slug.current}` : `/${slug.current}/1`

const Element = ({ title, cover, slug, __typename }) => (
  <StyledLink to={getLink(__typename, slug)}>
    <ImgContainer>
      <Img
        fluid={cover.asset.fluid}
        alt={title}
        imgStyle={{
          objectFit: "cover",
          objectPosition: "center center",
        }}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </ImgContainer>
    <TextContainer>{title}</TextContainer>
  </StyledLink>
)

export default Element
