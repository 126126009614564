import React from "react"
import styled from "styled-components"
import Citation from "./Citation"
import Carousel from "@lib/simba/carousel"
import { Parallax } from "react-scroll-parallax"
import googleLogo from "@lib/simba/theme/google.svg"

const Container = styled.section`
  background-color: ${({ theme }) => theme.citation.background};
  padding: ${({ theme }) => theme.paddingBox};
  text-align: center;
`

const ContainerGoogle = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.citation.fontSizeGoogle};
  img {
    height: 15px;
    margin: 0 5px;
  }
`

const Citations = ({ quotes, google }) => (
  <Parallax y={["-20px", "20px"]}>
    <Container>
      <Carousel
        enableAutoPlay
        showArrows={false}
        pagination={false}
        autoPlaySpeed={10000}
      >
        {quotes.map(citation => (
          <Citation key={citation.author} id={citation.author} {...citation} />
        ))}
      </Carousel>
      {google ? (
        <ContainerGoogle>
          Avis recueillis par <img src={googleLogo} alt="google" />
        </ContainerGoogle>
      ) : null}
    </Container>
  </Parallax>
)

export default Citations
