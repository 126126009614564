import React from 'react'
import Item from './Item'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

const Container = styled.section`
    overflow: hidden;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin: ${({ theme }) => theme.paddingBox};
    }
`

const InnerContainer = styled.section`
    width: 100%;
    display: grid;
    margin: auto;
    grid-template-columns: 1fr ;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        grid-template-columns:  1fr
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        grid-template-columns: repeat(5, 1fr);
    }
`
const Title = styled.div`
    color: black;
    font-size: 1.9rem;
    text-transform: uppercase;
    margin: ${({ theme }) => theme.paddingBox};
    text-align: center;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        width: ${({ theme }) => theme.maxWidth};
        display: grid;
        margin: auto;   
    }

`

let delay = -300
export default ({ items, title }) => (
    <Container>
        <Title>{title}</Title>
        <InnerContainer>
            {items.map(item => {
                delay = (d => d + 300)(delay)
                return <Fade key={item.id} delay={delay} bottom><Item {...item} /></Fade>
            })}
        </InnerContainer>
    </Container>
)