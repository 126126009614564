import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

const Container = styled.section`
  overflow: hidden;
  display: flex;
  min-width: 100vw;
  margin: ${({ theme }) => theme.paddingBox} 0;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    margin: calc(${({ theme }) => theme.paddingBox}*2) 0;
  }
`
const Illustration = styled.div`
  position: relative;
  min-width: 100%;
`
const ContainerImg = styled.div`
  position: absolute;
  background: green;
  z-index: -1;
  width: 100%;
  height: 100%;
`
const StyledImage = styled(Img)`
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 1;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
  }
`
const Infos = styled.div`
  flex: 0 0 50vw;
  margin: 0px -100% 0px 50vw;
  width: 100%;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex: 0 0 40vw;
    margin: 0px -100% 0px 60vw;
  }
`
const Background = styled.div`
  padding: ${({ theme }) => theme.paddingBox};
  background-color: rgba(0,0,0,0.7);
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: calc(${({ theme }) => theme.paddingBox}*3);
  }
`
const Text = styled.div`
  width: 100%;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 55%;
  }
`
const TitleInfo = styled.h1`
  position: relative;
  font-size: 1.1rem;
  font-weight: normal;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorTextTitle};
  margin: calc(${({ theme }) => theme.paddingBox}/4) auto;
  z-index: 9999;
  width: 100%;
  @media only screen and (min-width: ${({ theme }) =>theme.breakpoints.tablet}) {
    font-size: 1.5rem;
  }
  @media only screen and (min-width: ${({ theme }) =>theme.breakpoints.desktop}) {
    font-size: 2.5rem;
  }
  &:after{
    position: absolute;
    content: '';
    height: 2px;
    bottom: -4px; 
    left: 0;
    width: 25%;
    background: ${({ theme }) => theme.colors.secondary};
  }
`
const Resume = styled.div`
  color: ${({ theme }) => theme.colorTextTitle};
  font-size: 0.9rem;
  margin: 0;
  width: 100%;
  padding: calc(${({ theme }) => theme.paddingBox}/2) 0;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: calc(${({ theme }) => theme.paddingBox}*2) 0;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 1.3rem;
    width: 100%;
  }
`
const Button = styled.div`
  color: ${({ theme }) => theme.colorTextTitle};
  background-color: ${({ theme }) => theme.colors.secondary};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 1rem;
  padding: 7px 7px;
  font-size: 0.7rem;
  text-transform: uppercase;
  margin: auto;
  display: block;
  text-align: center;
  max-width: 60%;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 7px 0.5rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 1rem;
  }
`

const HomeArticle = ({ article }) => {

return(
  <Container>
    <Infos>
      <Background>
        <Text>
          <TitleInfo>{article.title}</TitleInfo>
          <Resume>{article.resume}</Resume>
          <Link to={`/${article.links[0].slug.current}`}><Button>En savoir plus &#x2192;</Button></Link>
        </Text>
      </Background>
    </Infos>
    <Illustration>
      <ContainerImg>
        <StyledImage Tag="section" fluid={article.cover.asset.fluid} />
      </ContainerImg>
    </Illustration>
  </Container>
)}
export default HomeArticle
