import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

const Container = styled.section`
  padding: calc(${({ theme }) => theme.paddingBox} / 2);
  color: black;
  display: none;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    display: inherit;
    max-width: ${({ theme }) => theme.breakpoints.desktop};
    margin: auto;
  }
`

const Button = styled.button`
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 2rem;
  padding: calc(${({ theme }) => theme.paddingBox} / 4)
    calc(${({ theme }) => theme.paddingBox});
  background: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all ease 300ms;
  ${({ active, theme }) =>
    active &&
    `
	color: white;
	background: ${theme.colors.secondary};
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  `}
  &:hover {
    color: white;
    background: ${({ theme }) => theme.colors.secondary};
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ nb }) => `repeat(${nb}, 1fr)`};
  gap: ${({ theme }) => theme.paddingBox};
  padding: 0 ${({ theme }) => theme.paddingBox};
`

const CardContainer = styled.div`
  padding: ${({ theme }) => theme.paddingBox}
    calc(${({ theme }) => theme.paddingBox}*2);
  overflow: hidden;
  position: relative;
`

const Card = styled.div`
  border-radius: 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    flex-direction: row;
    align-items: center;
  }
`

const CardImage = styled.div`
  height: 250px;
  overflow: hidden;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    height: auto;
    flex: 1;
  }
`

const CardTexts = styled.div`
  padding: ${({ theme }) => theme.paddingBox};
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    width: 50%;
  }
`

const CardTitle = styled.h1`
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  font-weight: normal;
`
const CardContent = styled.div`
  margin-bottom: calc(${({ theme }) => theme.paddingBox}*2);
`

export const CarouselControl = ({ items }) => {
  const [page, setPage] = useState(0)
  return (
    <Container>
      <ButtonContainer nb={items.length}>
        {items.map((item, index) => (
          <Button
            key={`btn-${item.key}`}
            onClick={() => setPage(index)}
            active={index === page}
          >
            {item.title}
          </Button>
        ))}
      </ButtonContainer>
      <CardContainer>
        <Card key={`item-${page}`}>
          <CardImage>
            <Img fluid={items[page].picture.asset.fluid} />
          </CardImage>
          <CardTexts>
            <CardTitle>{items[page].title}</CardTitle>
            <CardContent>{items[page].content}</CardContent>
            <Link to={`/${items[page].links[0].slug.current}`}><Button>En savoir plus</Button></Link>
          </CardTexts>
        </Card>
      </CardContainer>
    </Container>
  )
}
