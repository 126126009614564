import React from "react"
import styled from "styled-components"

const Champs = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`
const Input = styled.input`
    position: relative;
    right: -75px; top: 0;
    width: 70%;
    border: 0;
    background: white;
    padding: ${({ theme }) => `calc(${theme.paddingBox} / 2)`};
    margin: ${({ theme }) => `calc(${theme.paddingBox} / 2) 0`};
    border-bottom: 1px solid ${({ theme }) => theme.colorBorder};
    border-radius: 1rem;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        width: 100%;
        right: 0px;
    }
`
const Label = styled.label`
    position: absolute;
    font-size: 1rem;
    top: 15px; 
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        font-size: 1.2rem;
        left: -100px;
    }
`
export default ({ name , label, placeholder }) => (
    <Champs>
        <Label for={`${name}`}>{label}</Label>
        <Input type="text" name={`${name}`} placeholder={`${placeholder}`} required />
    </Champs>
)
