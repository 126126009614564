import React from "react"
import styled from "styled-components"
import star from "./star.svg"

const Quote = styled.p`
  white-space: pre-wrap;
  font-size: 5vw;
  color: ${({ theme }) => theme.colorLink};
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    font-size: 2vw;
  }
`
const AuthorContainer = styled.footer``
const Author = styled.p`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.citation.fontSizeAuthor};
`
const Role = styled.p``

const StarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    padding: ${({ theme }) =>
      `${theme.paddingBox} calc(${theme.paddingBox} / 4)`};
    margin: 0;
  }
`

const getOrder = order => (order && order < 6 ? order : 5)

export default ({ quote, author, role, rating }) => (
  <blockquote>
    <StarContainer>
      {[...Array(getOrder(rating)).keys()].map(key => (
        <img key={key} src={star} alt={"star"} />
      ))}
    </StarContainer>
    <Quote>{quote}</Quote>
    <AuthorContainer>
      <Author>{author}</Author>
      <Role>{role}</Role>
    </AuthorContainer>
  </blockquote>
)
