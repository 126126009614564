import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Container = styled.section`
    display: flex;
    flex-direction: column;
    padding: calc(${({ theme }) => theme.paddingBox}/4);
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        flex-direction: row;
        padding: ${({ theme }) => theme.paddingBox};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        flex-direction: column;
    }
`
const BorderIcon = styled.div`
    position: relative;
    max-height: 90px;
    min-height: 90px;
    height: 90px;
    width: 90px;
    max-width:90px;
    min-width:90px;
    margin: ${({ theme }) => theme.paddingBox} auto;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.secondary};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        max-height: 100px;
        min-height: 100px;
        height: 100px;
        width: 100px;
        max-width: 100px;
        min-width: 100px;
    }
`
const IconContainer = styled.div`
    height: 65px;
    width: 65px;
    position: absolute;
    top:0; bottom:0; left:0;right:0;
    margin: auto;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        height: 75px;
        width: 75px
    }
`
const TextContainer = styled.div`
    display: flex;
    width:100%;
    flex-direction: column;
    align-items: center:
    justify-content: center;
    margin: auto;
    text-align: center;
    color: ${({ theme }) => theme.colorsTextTitle};
    
`
const Title = styled.h2`
    letter-spacing: 2px;
    font-weight: normal;
    margin-bottom : calc(${({ theme }) => theme.paddingBox}/2);
`
const Subtitle = styled.h3`
    font-weight: normal;
`

export default ({ title, icon, subtitle, Link }) => (
    <Container>
        <BorderIcon>
            <IconContainer>
                <Img
                fluid={icon.asset.fluid} 
                imgStyle={{
                    objectFit: 'contain'
                }}/>
            </IconContainer>
        </BorderIcon>
        <TextContainer>
            <Title>{title}</Title>
            {Link ? Link : null}
        </TextContainer>
    </Container>
)
